import * as React from 'react';
import type { HeadFC } from 'gatsby';
import styled, { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  html, body {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
  }

  body {
    margin: 0;
    padding: 0;
    background: #f1f1f1;
    color: #444;
    font-family: -apple-system, Roboto, sans-serif, serif;
    font-size: 16px;
    line-height: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 40px solid #fff;

    @media (max-width: 768px) {
      border: 20px solid #fff;
    }
  }

  small {
    font-size: 50%;
  }
`;

const Main = styled.main`
  padding: 20px;
`;

const Title = styled.h1`
  padding: 0;
  text-align: center;
  text-transform: uppercase;
  font-weight: normal;
  margin: 0 0 50px 0;
  font-size: 4.5vw;

  @media (max-width: 768px) {
    font-size: 6vw;
  }
`;

const CompanyData = styled.div`
  padding: 0;
  text-align: center;
  text-transform: uppercase;
  font-size: 1rem;
  line-height: 1.8;
`;

const Linkedin = styled.a`
  color: salmon;
  text-transform: none;

  &:hover {
    text-decoration: none;
  }
`;

const IndexPage = () => {
  return (
    <Main>
      <GlobalStyle />
      <Title>
        Radosław Kowalewski <br /> <small>usługi programistyczne</small>
      </Title>
      <CompanyData>
        <p>
          NIP: 8442143752 <br />
          REGON: 522994226 <br />
          <Linkedin
            href="https://www.linkedin.com/in/kowalewskiradoslaw"
            target="_blank"
          >
            www.linkedin.com/in/kowalewskiradoslaw
          </Linkedin>
        </p>
      </CompanyData>
    </Main>
  );
};

export default IndexPage;

export const Head: HeadFC = () => (
  <title>Radosław Kowalewski - usługi programistyczne</title>
);
